.App {
  margin: 30px
}

h2 {
  margin-bottom: -15px;
  font-weight: 900;
  font-size: 40px;
}

.stats-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
}

.stats-prop {
  text-align: left;
  font-weight: 700;
  width: 49%;
}
.stats-val {
  width: 49%;
  font-style: italic;
}